import { FC } from 'react'

import { Badge } from 'ds/components/Badge'

import { Tag as TagModel } from 'lib/models/tag'

type Props = {
    tag: TagModel
    className?: string
}

export const Tag: FC<Props> = ({ tag, className }) => {
    return (
        <Badge className={className} bgColor={tag.color}>
            {tag.name}
        </Badge>
    )
}
