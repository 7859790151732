import { FC } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Button, Modal } from 'ds/components'

import { invoiceService } from 'lib/services/invoiceService'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'

type Props = {
    invoice: any
    closeModal: () => void
}

export const ArchiveInvoiceModal: FC<Props> = ({ invoice, closeModal }) => {
    const queryClient = useQueryClient()

    const { mutate } = useMutation({
        mutationFn: () =>
            invoiceService.deleteInvoice({
                invoice_id: invoice.invoice_id,
            }),
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            toastSuccess: 'Cobro archivado.',
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                queryClient.invalidateQueries({ queryKey: ['subscription'] })
                closeModal()
            },
        }),
    })

    return (
        <Modal title="Archivar Cobro" size="sm" onClose={closeModal}>
            <p>¿Seguro que quieres archivar este cobro?</p>
            <Button variant="danger" className="inline-md" onClick={() => mutate({})}>
                Confirmar y archivar
            </Button>
            <Button variant="secondaryText" onClick={closeModal}>
                Cancelar
            </Button>
        </Modal>
    )
}
