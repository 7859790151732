const CustomerAndOrderDetail = ({ customer, order_detail }) => {
    // Currently supports both
    // invoice.customer = 'CUSTOMER ABC'
    // invoice.customer = {customer: 'CUSTOMER ABC', ...}
    return (
        <>
            <span>{customer?.customer || customer}</span>
            {order_detail && (
                <span className="neutral-500"> / {order_detail}</span>
            )}
        </>
    )
}

export { CustomerAndOrderDetail }
