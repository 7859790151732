import { Button } from 'ds/components/Button'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { formatDateShort } from 'lib/utils/formatDateShort'
import { paymentService } from 'lib/services/paymentService'
import { castDecimal } from 'lib/utils/castDecimal'

const BulkRecordPaymentRow = ({ invoice, formData, getFormError, setFormError }) => {
    const queryClient = useQueryClient()
    const [result, setResult] = useState('')

    const remainingAmountToPay = castDecimal(invoice.amount_total)
        .minus(castDecimal(invoice.amount_paid))
        .toNumber()

    const { isPending, mutate } = useMutation({ mutationFn: paymentService.postPayment })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (getFormError()) return false

        mutate(
            {
                amount_paid: remainingAmountToPay,
                currency: invoice.currency,
                exchange: formData.exchange,
                details: formData.details,
                send_email: formData.send_email && Boolean(invoice.email),
                payment_date: formData.payment_date,
                payment_form: formData.paymentFormObject.code,
                invoice_payments_map: {
                    [invoice.invoice_id]: remainingAmountToPay,
                },
                customer_id: invoice.customer_id,
            },
            {
                onError: (error) => {
                    console.error(error)
                    toast.error(error)
                    setFormError('Algo salió mal.')
                    setResult(error)
                },
                onSuccess: (data) => {
                    if (data.error) {
                        toast.error(data.error)
                        setFormError(data.error)
                        setResult(data.error)
                        return
                    }
                    setResult('success')
                    toast.success(data.message)

                    queryClient.invalidateQueries({ queryKey: ['invoice'] })
                    queryClient.invalidateQueries({ queryKey: ['dashboard'] })
                    queryClient.invalidateQueries({ queryKey: ['kpis'] })
                    queryClient.invalidateQueries({ queryKey: ['subscription'] })
                },
            }
        )
    }

    const mainButtonText = formData.send_email
        ? 'Registrar pago y enviar'
        : 'Registrar pago'

    return (
        <div className="d-flex stacked-md align-items-center">
            <div className="inline-md">
                {!result ? (
                    <span className="fs-24 neutral-500">
                        <ion-icon name="ellipse-outline" />
                    </span>
                ) : result === 'success' ? (
                    <span className="fs-24 success-500">
                        <ion-icon name="checkmark-circle" />
                    </span>
                ) : (
                    <span className="fs-24 danger-500">
                        <ion-icon name="alert-circle-outline" />
                    </span>
                )}
            </div>
            <div className="flex-fill inline-md">
                <strong>
                    {invoice.customer?.customer || invoice.customer} {' · '} Cobro{' '}
                    {invoice.invoice_num}
                </strong>
                <div>
                    {formatDateShort(invoice.time_invoice)}
                    {' · '}
                    {formatCurrency(invoice.amount_total, 0, invoice.currency)}
                </div>
                {result === 'success' ? (
                    <div>Pagado</div>
                ) : (
                    <div>
                        Por cobrar:{' '}
                        {formatCurrency(remainingAmountToPay, 0, invoice.currency)}
                    </div>
                )}
                {Number(invoice.amount_paid) ? (
                    <strong className="warning-500">· *Ya tiene pago parcial.*</strong>
                ) : null}
            </div>
            <div>
                <Button onClick={handleSubmit} disabled={isPending || result}>
                    {isPending ? 'Generando...' : mainButtonText}
                </Button>
            </div>
        </div>
    )
}

export { BulkRecordPaymentRow }
