export const getManualPagination = ({
    paginationMetadata,
    filter,
    setFilter,
}) => {
    return {
        offset: paginationMetadata.offset,
        limit: paginationMetadata.limit,
        result_count: paginationMetadata.result_count,
        total_count: paginationMetadata.total_count,
        onPrevPage: () => setFilter({ offset: filter.offset - filter.limit }),
        onNextPage: () => setFilter({ offset: filter.offset + filter.limit }),
    }
}
