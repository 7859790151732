import { Badge } from 'ds/components/Badge'
import { formatDateShort } from 'lib/utils/formatDateShort'
import { differenceInCalendarDays } from 'date-fns'

const DueDateBadge = ({ timeDue, settled, relative = false }) => {
    const timeNowD = new Date()
    const timeDueD = new Date(timeDue)
    const daysUntilDue = differenceInCalendarDays(timeDueD, timeNowD)

    let display = { text: '', color: '' }
    if (relative) {
        if (settled && daysUntilDue < 0)
            display = { color: 'success', text: `hace ${Math.abs(daysUntilDue)} día(s)` }
        else if (settled && daysUntilDue === 0) display = { color: 'success', text: `hoy` }
        else if (settled && daysUntilDue === 1) display = { color: 'success', text: `mañana` }
        else if (settled && daysUntilDue > 0)
            display = { color: 'success', text: `en ${daysUntilDue} días` }
        else if (daysUntilDue < 0)
            display = { color: 'danger', text: `hace ${Math.abs(daysUntilDue)} día(s)` }
        else if (daysUntilDue === 0) display = { color: 'warning', text: 'hoy' }
        else if (daysUntilDue === 1) display = { color: 'warning', text: 'mañana' }
        else if (daysUntilDue > 0) display = { color: 'primary', text: `en ${daysUntilDue} días` }
    } else {
        if (settled) display = { color: 'success', text: `${formatDateShort(timeDueD)}` }
        else if (daysUntilDue < 0) display = { color: 'danger', text: formatDateShort(timeDueD) }
        else if (daysUntilDue === 0) display = { color: 'warning', text: formatDateShort(timeDueD) }
        else if (daysUntilDue === 1) display = { color: 'warning', text: formatDateShort(timeDueD) }
        else if (daysUntilDue > 0) display = { color: 'primary', text: formatDateShort(timeDueD) }
    }

    return <Badge variant={display.color}>{display.text}</Badge>
}

export { DueDateBadge }
