import { FC, useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Modal } from 'ds/components/Modal'
import { TagSelect } from './TagSelect'
import { Button } from 'ds/components/Button'

import { tagService } from 'lib/services/tagService'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'

type Props = {
    invoices: any[]
    onClose: () => void
}

export const BulkTagModal: FC<Props> = ({ invoices, onClose }) => {
    const [tagIds, setTagIds] = useState<number[]>([])

    const { mutate } = useMutation({
        mutationFn: () => {
            const invoice_ids = invoices.map((invoice) => invoice.invoice_id)
            return tagService.bulkTag({ tag_ids: tagIds, invoice_ids })
        },
    })

    return (
        <Modal size="sm" title="Tag Cobros" onClose={onClose}>
            <TagSelect
                allowCreate
                label="Tag(s)"
                placeholder="Escoge al menos un tag para aplicar a los cobros."
                onChange={setTagIds}
                selectedTagIds={tagIds}
            />

            <Button
                className="mt-4"
                disabled={tagIds.length === 0}
                onClick={() => {
                    if (!tagIds.length) return
                    mutate(undefined, {
                        onError: mutationOnError({
                            toastError: 'Error al aplicar tags a los cobros.',
                        }),
                        onSuccess: mutationOnSuccess({
                            toastSuccess: 'Tags aplicados.',
                            onSuccess: () => onClose(),
                        }),
                    })
                }}
            >
                Guardar
            </Button>
        </Modal>
    )
}
