import { FC, useState } from 'react'

import { Modal, Button } from 'ds/components'
import { RecordPaymentFormLoader } from '../RecordPaymentFormLoader'

type Props = {
    canCreatePayment: boolean
    invoice: any
}

export const PaidIcon: FC<Props> = ({ canCreatePayment, invoice }) => {
    const [showModal, setShowModal] = useState(false)
    const isSettled = !!invoice.settled_date

    return (
        <div>
            <Button
                disabled={!canCreatePayment}
                size="sm"
                variant={isSettled ? 'successText' : 'secondaryText'}
                onClick={(e) => {
                    e.stopPropagation()
                    if (canCreatePayment && !isSettled) setShowModal(true)
                }}
                icon={isSettled ? 'check-circle-fill' : 'check-circle'}
            />

            {showModal ? (
                <Modal title="Registrar pago" onClose={() => setShowModal(false)}>
                    <RecordPaymentFormLoader
                        customer_id={invoice.customer_id}
                        invoices={[invoice]}
                        onSuccess={() => setShowModal(false)}
                    />
                </Modal>
            ) : null}
        </div>
    )
}
