import { FC, useMemo } from 'react'

import { DatePicker, SelectInput, Checkbox } from 'ds/components'
import { BulkRecordPaymentRow } from './BulkRecordPaymentRow'

import { useForm } from 'lib/hooks/useForm'
import { formaDePago } from 'lib/constants/formaDePago'
import { dateStringToDate, dateToDateString } from 'lib/utils/dateStringUtils'
import { formatDateMonthYear } from 'lib/utils/formatDateMonthYear'

type Props = { invoices: any[] }

export const BulkRecordPayments: FC<Props> = ({ invoices }) => {
    const initialValues = useMemo(
        () => ({
            paymentFormObject: formaDePago.find((o) => o.code === '04') || null,
            send_email: true,
            payment_date: dateToDateString(new Date()),
        }),
        []
    )

    const { formData, formContext, handleBlur, handleChange, setFormError, getFormError } = useForm(
        initialValues,
        { payment_date: { type: 'dateString', required: true } }
    )

    return (
        <div>
            <div className="row stacked-md gy-2">
                <DatePicker
                    label="Fecha recibido"
                    className="col-lg"
                    formContext={formContext.payment_date}
                    value={dateStringToDate(formData.payment_date)}
                    onDateStringChange={(value) => handleChange('payment_date', value)}
                    onBlur={() => handleBlur('payment_date')}
                    help={
                        formData.payment_date
                            ? formatDateMonthYear(formData.payment_date)
                            : 'Formato en dd/mm/yyy'
                    }
                />
                <SelectInput
                    label="Forma de pago"
                    className="col-lg"
                    formContext={formContext.paymentFormObject}
                    value={formData.paymentFormObject}
                    onChange={(o) => handleChange('paymentFormObject', o)}
                    items={formaDePago}
                    getItemId={(o) => o.code}
                    itemToString={(o) => `${o.code} - ${o.description}`}
                />
                <Checkbox
                    className="col-lg"
                    checked={formData.send_email}
                    onChange={(value) => handleChange('send_email', value)}
                >
                    Enviar confirmación por email
                </Checkbox>
            </div>

            {invoices.map((invoice) => (
                <div key={invoice.invoice_id}>
                    <BulkRecordPaymentRow
                        invoice={invoice}
                        formData={formData}
                        getFormError={getFormError}
                        setFormError={setFormError}
                    />
                </div>
            ))}
        </div>
    )
}
