import { FC } from 'react'

import { Tooltip, Icon, Badge } from 'ds/components'

type Props = {
    invoice: any
}

export const CfdiBadge: FC<Props> = ({ invoice }) => {
    if (invoice.cfdi_i_payment_method)
        return (
            <>
                <Badge variant="success">Emitida {invoice.cfdi_i_payment_method}</Badge>
                {invoice.cfdi_p_cfdi_id ? <Badge variant="success">Pago</Badge> : null}
                {invoice.has_cfdi_cancellation_pending ? (
                    <Tooltip
                        trigger={
                            <Badge variant="warning">
                                <Icon icon="ExclamationTriangle" />
                            </Badge>
                        }
                    >
                        Cancelación pendiente
                    </Tooltip>
                ) : null}
            </>
        )
    if (invoice.has_cancelled_cfdi) return <Badge variant="danger">Cancelada</Badge>
    return <Badge variant="neutral">Sin factura</Badge>
}
